import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { projects } from "../../components/data/projects"

import ProjectHeader from "../../components/project-header/project-header"
import ReadNext from "../../components/read-next/read-next"
import Index from "../../components/index"
import { RoughNotation } from "react-rough-notation"
import { StaticImage } from "gatsby-plugin-image"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock } from "@fortawesome/free-solid-svg-icons"

const chapters = [
  {
    name: `Overview`,
    scrollTo: 'overview',
  },
  {
    name: 'Brainstorming',
    scrollTo: 'brainstorming',
  },
  {
    name: 'Design',
    scrollTo: 'design',
  },
  {
    name: 'Development',
    scrollTo: 'development',
  },
];

const theme = projects['workaholic'].themeColor;

const ProjectPage = () => (
  <>
    <Seo title="Workaholic" />
    <ProjectHeader projectObj={projects['workaholic']} />
    <section className={`fix-top fix-bottom`} id={`overview`}>
      <Index chapters={chapters} />
      <div className={`content--md-center`}>
        <h6 style={{ color: theme}}>Overview</h6>
        <h2 className={`add-margin-bottom`}>Problem Statement</h2>
        <p className={`primary lh`}>From my experience working with tech companies, I realised that employees spend almost 5-8 hours in a day working in front of their screens. This work requires them to be seated for long hours which affects their physical health in terms of eyesight, posture, neck pain, dehydration and headaches. Personally, after a few weeks, I felt a decline in my own health while working in an office. To counter this problem, I spent an hour and developed a simple program that notified me to stand up and walk around according to my schedule. Upon discussing this incident with my peers, I realised that this problem wasn't limited to tech offices. A lot of people now have their daily tasks centered around devices. Moreover, with social media and entertainment through Netflix and YouTube, our devices are designed to be engaging and distracting. As a small step towards building a healthy interaction with our devices, I tried to scale my prototype and design Workaholic.</p>
        <p className={`lh`}>Note: I do acknowledge that YouTube has a setting that allows people to limit their usage and notifies them when they've been watching continuously. I find it extremely convenient but I'm not sure about the familiarity of such settings among users.</p>
      </div>
    </section>
    <section className={`fix-top fix-bottom`} id={`brainstorming`}>
      <div className={`content--md-center`}>
      <h6 style={{ color: theme}}>Brainstorming</h6>
        <h2 className={`add-margin-bottom`}>Features</h2>
        <p className={`primary lh`}>While considering this problem, I thought what every millennial thinks about any problem — There must be an app for that. Most certainly, I was not wrong. There were several applications I came across, each solving a certain problem about these health issues. Some monitored screen time while some provided tips to improve eyesight. However, two things lacked in most solutions I used. Firstly, I would have needed five different platforms to see insights about my device usage for all the health concerns I raised. Secondly, I felt such applications need to establish a connection with the user to actually help improve their lifestyle. Measuring screen-time on its own is not motivating and lacks the user interaction required.</p>
        <p className={`primary lh`}>During the design process for workaholic, I tried to incorporate whatever I learnt from these solutions to build a platform that caters to all these health issues and provides an interaction that feels rewarding.</p>
        <div className={`project-image`}>
          <StaticImage
            src={'../../images/projects/workaholic/Brainstorming.png'}
            width={1200}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Stages of the programming experience"
            loading={`eager`}
          />
          <p className={`caption`}>Brainstorming features</p>
        </div>
      </div>
    </section>
    <section className={`fix-top fix-bottom`} id={`design`}>
      <div className={`content--md-center`}>
        <h6 style={{ color: theme}}>Design</h6>
        <h2 className={`add-margin-bottom`}>Challenges</h2>
        <p className={`primary lh`}>There are some obvious challenges that this app needs to overcome to fulfill its goal. Deciding the health activities, understanding the user's daily schedule through an interactive and simple onboarding process, making sure there is a limit to the number of interventions in a day — to name a few. Additionally, there were some crucial points evaluated during the process.</p>
        <h4>Platform?</h4>
        <p className={`primary lh`}>
        What type of technology is the most suitable? A software? A web platform? A mobile app or a chrome extension? Envisioning the initial setup that is required everyday was extremely important. I decided that I want to make something that is accessible and accurate. For now, I felt that a software would be too inconvenient and a mobile application alone could not account for a lot of the screen time. Ultimately, I decided a web app supported by a mobile version would be suitable.
        </p>
        <h4 className={`add-margin-top`}>Designing <RoughNotation type={`circle`} show color={theme}>subtle</RoughNotation> notifications</h4>
        <p className={`primary lh`}>
        An important consideration besides promoting ergonomic activities is to understand the cues necessary to instruct the person to do an activity. It is also important that these notifications were subtle and were not intrusive or offensive in any manner. They should be instructive enough to help the person understand what to do, and flexible enough to understand the person's priorities.
        </p>
        <h4 className={`add-margin-top`}>Keeping the users motivated</h4>
        <p className={`primary lh`}>
        It is crucial that the users are motivated to open the app and do these activities. Since productivity apps lack dynamics or instant rewards that motivates users to check these apps, I tried to incorporate some elements of gamification. Users are rewarded by maintaining an everyday streak score along with weekly notifications and graphs to help them realise the efficacy of the application. Additionally, each activity that the user completes gets added to the health tab.
        </p>
        <h4 className={`add-margin-top`}>Combining <RoughNotation type={`underline`} show color={theme}>productivity</RoughNotation> and health</h4>
        <p className={`primary lh`}>
        A health application that promotes users to take breaks from their screens must ensure that the user's productivity is not just unaffected, but increased.
        </p>
      </div>
    </section>
    <section className={`fix-top fix-bottom`}>
      <div className={`content--md-center`}>
        <h2 className={`add-margin-bottom`}>Designs</h2>
        <div className={`project-image`}>
          <StaticImage
            src={'../../images/projects/workaholic/Wireframe.png'}
            width={1200}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Stages of the programming experience"
            loading={`eager`}
          />
          <p className={`caption`}>Wireframe</p>
        </div>
        <div className={`project-image`}>
          <StaticImage
            src={'../../images/projects/workaholic/Dashboard Data.png'}
            width={1200}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Stages of the programming experience"
            loading={`eager`}
          />
          <p className={`caption`}>Dashboard</p>
        </div>
        <div className={`project-image`}>
          <StaticImage
            src={'../../images/projects/workaholic/Health.png'}
            width={1200}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Stages of the programming experience"
            loading={`eager`}
          />
          <p className={`caption`}>Health: Expanded View</p>
        </div>
        <div className={`project-image`}>
          <StaticImage
            src={'../../images/projects/workaholic/Screen Time.png'}
            width={1200}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Stages of the programming experience"
            loading={`eager`}
          />
          <p className={`caption`}>Screen Time: Expanded View</p>
        </div>
        <div className={`project-image`}>
          <StaticImage
            src={'../../images/projects/workaholic/Activities.png'}
            width={1200}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Stages of the programming experience"
            loading={`eager`}
          />
          <p className={`caption`}>Activites: Expanded View</p>
        </div>
        <div className={`project-image`}>
          <StaticImage
            src={'../../images/projects/workaholic/Tasks.png'}
            width={1200}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Stages of the programming experience"
            loading={`eager`}
          />
          <p className={`caption`}>Tasks: Expanded View</p>
        </div>
        <div className={`project-image`}>
          <StaticImage
            src={'../../images/projects/workaholic/Notes.png'}
            width={1200}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Stages of the programming experience"
            loading={`eager`}
          />
          <p className={`caption`}>Notes: Expanded View</p>
        </div>
      </div>
    </section>
    <section className={`fix-top fix-bottom`}>
      <div className={`content--md-center`}>
      <h6 style={{ color: theme}}>Development</h6>
      <h2 className={`add-margin-bottom`}>Prototype <span className={`icon`}><FontAwesomeIcon icon={faLock} /></span></h2>
      <p className={`primary lh`}>Currently, Workaholic is under development and testing. If you're interested, write to me at <a href="">thukral.abhinav@gmail.com</a> to learn more.</p>
      </div>
    </section>
    <ReadNext projectObj={projects['postureAnalysis']} />
  </>
)

export default ProjectPage
